
/* Flagship Content - Covers /galahome pages */

.galahome {
  /*a {
    color: #0288d1;
  }

  a:focus, a:hover, a:active {
    color: #0273b0;
  }*/

  .chesterBlue {
    color: #0288d1;
  }

  .flex-panel-top-blue {
    border-top: 3px solid #0288d1;
  }
}

.flagship-content {
    padding: 48px 0;
    color: #FFF;
    background-color: #333;
    background-position: center center;
    background-blend-mode: overlay;
    background-repeat: repeat-y;
}
.list-group-flagship-content {
    margin: 0;
}
.list-group-flagship-content a.list-group-item:focus, .list-group-flagship-content a.list-group-item:hover , .list-group-flagship-content a.list-group-item:active {
    background-color: #0288d1;
    color: #fff;
}

.list-group-flagship-content a.list-group-item {
    color: $body-color;
}

.pondsForgeBG {
	min-height: calc(100vh - 3.25rem);
	background: #0288d1;
  background: linear-gradient(to left, rgba(2, 136, 209, 0.55), rgba(2, 136, 209, 0.55)), url("https://static.chesterlestreetasc.co.uk/global/img/burnsmeet/PondsForgeBurnsBG.jpg") no-repeat 100% auto center;
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	background-position: center center;
	background-attachment: scroll;
	background-size: cover;
	background-repeat: repeat-y;
	overflow-y: hidden;
}

.galahome .flex-panel-top-blue {
	border-top: 3px solid #fff;
	background: rgba(51,51,51,0.5);
	color: #fff;
}
.galahome .flex-panel-link-group a:hover {
 	background: rgba(51,51,51,0.7);
 	color: #fff;
 }


@include media-breakpoint-up(md) {
  .pondsForgeBG {
  	min-height: calc(100vh - 3.25rem);
  	background: #0288d1;
    background: linear-gradient(to left, rgba(2, 136, 209, 0.55), rgba(2, 136, 209, 0.55)), url("https://static.chesterlestreetasc.co.uk/global/img/burnsmeet/PondsForgeBurnsBG.jpg") no-repeat fixed center;
  	-webkit-backdrop-filter: blur(5px);
  	backdrop-filter: blur(5px);
  	background-position: center center;
  	background-attachment: scroll;
  	background-size: cover;
  	background-repeat: repeat-y;
  	overflow-y: hidden;
  }
  .galahome .flex-panel-top-blue {
  	border-top: 3px solid #0288d1;
  }
}
