/* Post Footer for Pagination */

.pagination {
	margin: 2rem 0 1rem 0;
}
.cls-post-footer {
    background-color: $cell-bg;
    padding: 0.8rem 0.8rem 0rem 0.8rem;
    margin: 1rem 0 0 0;
}

.cls-pager>a {
    background-color: $cell-bg;
    padding: 1rem 0.8rem;
    display: block;
    min-height: 2rem;
    font-size: 1.2rem;
}
.cls-pager>a:hover {
    background-color: darken($cell-bg, 10%);
    text-decoration: none;
}
.cls-pager>a:focus {
    background-color: darken($cell-bg, 10%);
    text-decoration: none;
}
.cls-pager-parent {
    list-style: none;
    padding: 0;
}
.cls-pager-left {
    margin: 0 -0.8rem 0 -0.8rem;
}
.cls-pager-right {
    text-align: right;
    margin: 0 -0.8rem 0 -0.8rem;
}
