.front-page {
  background: $front-page-bg;
  color: $front-page-color;
  margin: -1rem 0 0 0;
  padding: 1rem 0;
}

.have-full-height .front-page {
  min-height:70vh;
}

.news-grid {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  padding: 0;
}

@include media-breakpoint-up(sm) {
  .news-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-up(lg) {
  .news-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.news-grid {
  a {
    display: grid;
    background: $front-page-link-bg;
    padding: 1rem;
    color: $front-page-link-color;
    @include border-radius($alert-border-radius);
    align-content: space-between;
    text-decoration: none;
  }

  a:hover, a:active, a:focus {
    background: $front-page-link-bg-hover;
    color: $front-page-link-color-hover;
    text-decoration: none;
  }

  .title {
    font-weight: 600;
    display: flex;
    margin: 0 0 0.5rem 0;
  }

  .category {
    color: $link-color;
    display: flex;
    line-height: normal;
  }
}

// SCDS Front Page

.front-page-purple-section {
  background: $primary;
  color: $white;
}

.front-page-alternate-section {
  background: $light;
  color: $body-color;
}