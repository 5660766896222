/* Post Tags */

.p-tags>a{
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    border-radius: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	color: #333;
    background-color: #fff;
    border-color: #ccc
}
.p-tags>a:hover{
    color: #FFF;
    background-color: #005fbd;
    border-color: #adadad;
	text-decoration:none
}
.p-tags>a:active{
	color: #FFF;
    background-color: #0055aa;
    border-color: #adadad;
	text-decoration:none
}

.nocomments {
  margin: 0;
  padding: 0;
}
