/* Cookie Notice CSS */

#cookie-law {
    background: $dark;
    color: #FFF;
    margin: 0;
    padding: 0.8rem 0 0.8rem 0;
}
#cookie-law .alert-link {
    text-decoration: underline;
	color: #ffffff;
}
#cookie-law .alert-link:hover {
	color: #ffffff;
}
